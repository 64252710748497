import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import axios from "axios";
import WebFont from "webfontloader";
import "./App.css";
import { Input } from "./Input";
import titanLogo from "./images/titan_logo.webp";
import userImage from "./images/user.webp";
import inviteImage from "./images/key.webp";
import passwordVisible from "./images/password_visible.webp";
import passwordHidden from "./images/password_hidden.webp";
import { AnimatedText } from "./AnimatedText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = "https://foundry.whentitansfell.com/api/";

const verifyKeyEndpoint = api + "verify_key";
const createUserEndpoint = api + "user";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 30px;
`;

const StyledLogo = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
`;

const StyledTitle = styled.div`
  font-family: "Lazar";
  font-size: 10em;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  color: #fff;
  // Select second child
  & > *:nth-child(2) {
    color: #ea0e6c;
  }
`;

const StyledSubtitle = styled.div`
  font-family: "Nuernberg";
  font-size: 3em;
  color: #ffffffb1;
`;

const animateKeyframes = keyframes`
  0% {
    filter: blur(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    filter: blur(100px);
    transform: translateY(-200px);
    opacity: 0;
  }
`;

const fadeInKeyframes = keyframes`
  0% {
    filter: blur(100px);
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    transform: translateY(0);
    opacity: 1;
  }
`;
type MainDivProps = {
  fadeOut?: boolean;
  fadeIn?: boolean;
};

const MainDiv = styled.div<MainDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  gap: 20px;
  padding: 50px;
  animation: ${(p) => (p.fadeOut ? animateKeyframes : "")} 2s ease-in-out;

  ${(p) =>
    p.fadeIn &&
    css`
      animation: ${fadeInKeyframes} 2s ease-in-out;
    `}
`;

const TypeText = styled.div`
  font-family: "NKDuy";
  font-size: 1em;
  color: #fff;
  text-align: center;
  // Force new line
  white-space: pre-wrap;
`;

const StyledButton = styled.button`
  all: unset;
  font-family: "NKDuy";
  font-size: 1em;
  color: #fff;
  background-color: #ea0e6c;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #fff;
    color: #ea0e6c;
  }
  transition: all 0.1s ease-in-out;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #ea0e6c;
      color: #fff;
    }
  }
  &:focus {
    border: 1px solid #fff;
  }
`;

const StyledPasswordRequirements = styled.div`
  position: absolute;
  // position above the input
  bottom: 40px;
  padding: 10px;
  left: 110px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "NKDuy";
  font-size: 0.75em;
  background-color: black;
  border-radius: 5px;
`;

type StyledPasswordRequirementProps = {
  met: boolean;
};
const StyledPasswordRequirement = styled.div<StyledPasswordRequirementProps>`
  ${(p: StyledPasswordRequirementProps) =>
    p.met
      ? css`
          color: #ffffffae;
        `
      : css`
          color: #4ee9c2;
        `}
`;

function App() {
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState<"invite" | "typeText" | "signUp">(
    "invite"
  );
  const [inviteFade, setInviteFade] = useState(false);
  const [typeTextFade, setTypeTextFade] = useState(false);

  const [inviteKey, setInviteKey] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [startValidation, setStartValidation] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [passwordNeedsLength, setPasswordNeedsLength] = useState(true);
  const [passwordNeedsUppercase, setPasswordNeedsUppercase] = useState(true);
  const [passwordNeedsLowercase, setPasswordNeedsLowercase] = useState(true);
  const [passwordNeedsNumber, setPasswordNeedsNumber] = useState(true);
  const [passwordNeedsSpecial, setPasswordNeedsSpecial] = useState(true);

  useEffect(() => {
    // Load fonts
    const fonts = ["Lazar", "Nuernberg", "NKDuy"];
    WebFont.load({
      custom: {
        families: fonts,
      },
      active: () => setLoaded(true),
    });
  }, []);

  useEffect(() => {
    if (startValidation) {
      validatePassword(password);
    }
  }, [password, startValidation]);

  const verifyKey = async (key: string) => {
    // Add key to Authorization header
    const headers = {
      Authorization: `token ${key}`,
    };
    try {
      const response = await axios.get(verifyKeyEndpoint, { headers });
      console.log(response);
      if (response.status === 200) {
        setInviteFade(true);
        setTimeout(() => {
          setState("typeText");
        }, 1000);
        setTimeout(() => {
          setTypeTextFade(true);
        }, 10000);
        setTimeout(() => {
          setState("signUp");
        }, 12000);
      }
    } catch (e: any) {
      if (e.response) {
        console.log(e.response);
        if (e.response.status === 403) {
          toast("Invalid key!");
        } else {
          toast("An error occurred!");
        }
      } else {
        console.log(e);
        toast("An error occurred!");
      }
    }
  };

  const validatePassword = (password: string) => {
    let valid = true;
    // Password must be at least 8 characters long
    if (password.length < 8) {
      setPasswordNeedsLength(true);
      valid = false;
    } else {
      setPasswordNeedsLength(false);
    }

    // Password must contain at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setPasswordNeedsUppercase(true);
      valid = false;
    } else {
      setPasswordNeedsUppercase(false);
    }

    // Password must contain at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setPasswordNeedsLowercase(true);
      valid = false;
    } else {
      setPasswordNeedsLowercase(false);
    }

    // Password must contain at least one number
    if (!/[0-9]/.test(password)) {
      setPasswordNeedsNumber(true);
      valid = false;
    } else {
      setPasswordNeedsNumber(false);
    }

    // Password must contain at least one special character
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
      setPasswordNeedsSpecial(true);
      valid = false;
    } else {
      setPasswordNeedsSpecial(false);
    }

    setIsPasswordValid(valid);
  };

  const signUp = async (name: string, password: string) => {
    const body = {
      name,
      password,
    };

    const headers = {
      Authorization: `token ${inviteKey}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(createUserEndpoint, body, { headers });
      if (response.status === 201) {
        toast("Success! Account created. Redirecting to Foundry...");
        setTimeout(() => {
          window.location.href = "https://foundry.whentitansfell.com/";
        }
        , 3000);
      } else {
        toast("An error occurred!");
      }
    } catch (e: any) {
      if (e.response) {
        console.log(e.response);
        switch (e.response.status) {
          case 400:
            toast("Invalid name or password!");
            break;
          case 403:
            toast("Invalid key!");
            break;
          case 409:
            toast("Name already taken!");
            break;
          default:
            toast("An error occurred!");
            break;
        }
      } else {
        toast("An error occurred!");
      }
    }
  };

  return loaded ? (
    <StyledApp>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{ backgroundColor: "#ea0e6c", color: "#fff" }}
      />
      <StyledLogo src={titanLogo} alt="Titan Logo" />
      <StyledTitle>
        <div>WHEN</div>
        <div>TITANS</div>
        <div>FELL</div>
      </StyledTitle>
      <StyledSubtitle>Become a titan reaper.</StyledSubtitle>

      {{
        invite: (
          <MainDiv fadeOut={inviteFade}>
            <Input
              iconSrc={inviteImage}
              label="Speak in forbidden tongues"
              description="enter your invitation key."
              placeholder="invite key"
              type="password"
              onChange={(e) => setInviteKey(e.target.value)}
              value={inviteKey}
            />
            <StyledButton onClick={() => verifyKey(inviteKey)}>
              Enter
            </StyledButton>
          </MainDiv>
        ),
        typeText: (
          <MainDiv fadeOut={typeTextFade}>
            <TypeText>
              <AnimatedText text="All that decays and rots will sunder" />
              <br />
              <AnimatedText text="Life shall be reaped, then sowed anew" delay={1} />
              <br />
              <AnimatedText text="Born of marrow, kin to bone" delay={2}/>
            </TypeText>
          </MainDiv>
        ),
        signUp: (
          <MainDiv fadeIn={true}>
            <Input
              iconSrc={userImage}
              label="Unveil thine soul"
              description="enter your name."
              placeholder="name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Input
              iconSrc={passwordHidden}
              toggledIconSrc={passwordVisible}
              toggleType="text"
              isToggled={isPasswordVisible}
              onToggle={() => setIsPasswordVisible(!isPasswordVisible)}
              label="Divulge your secrets"
              description="enter a password."
              placeholder="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setStartValidation(true);
              }}
              value={password}
              error={!isPasswordValid}
              onFocus={() => setShowPasswordRequirements(true)}
              onBlur={() => setShowPasswordRequirements(false)}
            >
              {showPasswordRequirements && (
                <StyledPasswordRequirements>
                  <StyledPasswordRequirement met={passwordNeedsLength}>
                    At least 8 characters
                  </StyledPasswordRequirement>
                  <StyledPasswordRequirement met={passwordNeedsUppercase}>
                    At least 1 uppercase letter
                  </StyledPasswordRequirement>
                  <StyledPasswordRequirement met={passwordNeedsLowercase}>
                    At least 1 lowercase letter
                  </StyledPasswordRequirement>
                  <StyledPasswordRequirement met={passwordNeedsNumber}>
                    At least 1 number
                  </StyledPasswordRequirement>
                  <StyledPasswordRequirement met={passwordNeedsSpecial}>
                    At least 1 special character
                  </StyledPasswordRequirement>
                </StyledPasswordRequirements>
              )}
            </Input>
            <StyledButton
              onClick={() => {
                signUp(name, password);
              }}
              disabled={
                password.length === 0 || name.length === 0 || !isPasswordValid
              }
            >
              Sign Up
            </StyledButton>
          </MainDiv>
        ),
      }[state] || <div>Invalid state</div>}
    </StyledApp>
  ) : (
    <div>Loading...</div>
  );
}

export default App;
