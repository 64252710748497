import React, { useEffect } from "react";
import styled, { css } from "styled-components";

const StyledInputWithIcon = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

type StyledIconProps = {
  toggle?: boolean;
};

const StyledIcon = styled.img<StyledIconProps>`
  width: 100px;
  height: 100px;
  ${(p) =>
    p.toggle &&
    css`
      cursor: pointer;
    `}
`;

const StyledLabel = styled.div`
  font-family: "Nuernberg";
  font-size: 2em;
  color: #fff;
`;

const StyledDescription = styled.div`
  font-family: "NKDuy";
  font-size: 0.75em;
  color: #ffffff8f;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

type StyledInputProps = {
  error?: boolean;
};
const StyledInput = styled.input<StyledInputProps>`
  height: 30px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ffffffb1;
  background-color: transparent;
  color: #fff;
  font-family: "NKDuy";
  font-size: 1em;
  padding: 0 10px;
  &:focus {
    outline: none;
    border: 1px solid #fff;
  }
  ${(p: StyledInputProps) =>
    p.error &&
    css`
      border: 1px solid #ea0e6c;
      &:focus {
        border: 1px solid #ea0e6c;
      }
    `}
`;

type InputProps = {
  iconSrc?: string;
  toggledIconSrc?: string;
  label?: string;
  description?: string;
  isToggled?: boolean;
  onToggle?: () => void;
  toggleType?: string;
  error?: boolean;
  children?: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = (props: InputProps) => {
  const {
    iconSrc,
    toggledIconSrc,
    label,
    description,
    isToggled,
    onToggle,
    toggleType,
    type,
    error,
    value,
    children,
    ...rest
  } = props;
  const [inputType, setInputType] = React.useState(type);

  useEffect(() => {
    setInputType(isToggled ? toggleType : type);
  }, [isToggled, toggleType, type]);

  return (
    <StyledInputWithIcon>
      {iconSrc && (
        <StyledIcon
          src={isToggled ? toggledIconSrc : iconSrc}
          toggle={onToggle !== undefined}
          onClick={() => {
            if (onToggle) onToggle();
          }}
        />
      )}

      <StyledInputContainer>
        <div>
          {label && <StyledLabel>{label}</StyledLabel>}
          {description && <StyledDescription>{description}</StyledDescription>}
        </div>
        <StyledInput {...rest} type={inputType} value={value} error={error} />
      </StyledInputContainer>
      {children}
    </StyledInputWithIcon>
  );
};
