// Create a react component that takes a string of text and animates letter by letter using the blur filter, having each letter blur into flocus, one after another using styled components and react hooks.
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const animateKeyframes = keyframes`
  0% {
    filter: blur(60px);
  }
  100% {
    filter: blur(0px);
  }
`;

type StyledLetterProps = {
  delay: number;
};
const StyledLetter = styled.span<StyledLetterProps>`
  font-family: "NKDuy";
  color: #fff;
  filter: blur(60px);
  /* transform: translateY(-200px); */
  /* opacity: 1; */
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  animation: ${animateKeyframes} 1.5s ease-in-out
    ${(p: StyledLetterProps) => p.delay}s forwards;
`;

type AnimatedTextProps = {
  text: string;
  delay?: number;
};

export const AnimatedText = ({ text, delay = 0 }: AnimatedTextProps) => {
  const [textArray, setTextArray] = useState<string[]>([]);

  useEffect(() => {
    setTextArray(text.split(""));
  }, [text]);

  return (
    <div>
      {textArray.map((letter, index) => (
        <StyledLetter key={index} delay={index * 0.1 + delay}>
          {letter}
        </StyledLetter>
      ))}
    </div>
  );
};
